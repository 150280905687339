import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Img from "gatsby-image";
import { Helmet } from "react-helmet";

// import { useInView } from "react-intersection-observer";
// import anime from "animejs"
import * as css from "./index.module.scss";

const IndexPage = ({ location }) => {
  const siteUrl = location.origin ? location.origin : "";

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "akcie.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 598, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  // * seo settings
  let meta = [];
  meta.push({ name: "og:image", content: data.file.childImageSharp.fluid.src });
  meta.push({ name: "twitter:image", content: siteUrl+data.file.childImageSharp.fluid.src });
  // if (!!seo && !!seo.title) {
  //   meta.push({ property: `og:title`, content: seo.title });
  //   meta.push({ property: `tw:title`, content: seo.title });
  // }
  // if (!!seo && !!seo.description) {
  //   meta.push({ name: `description`, content: seo.description });
  //   meta.push({ property: `og:description`, content: seo.description });
  //   meta.push({ property: `tw:description`, content: seo.description });
  // }
  // if (!!image && !!image.publicURL) {
  //   meta.push({ name: "og:image", content: image.publicURL });
  //   meta.push({ name: "twitter:image", content: image.publicURL });
  // }
  // if (!!seo && !!seo.keywords) {
  //   meta.push({ property: `keywords`, content: seo.keywords });
  // }
  return (
    <>
      <Helmet meta={meta} />
      <div className={css.wrapper}>
        <h1>
          Nadace
          <br /> Svoboda slova
          <br /> a myšlení
        </h1>
        <div className={css.center}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Voucher nadace Svoboda slova"
            className={css.image}
          />
        </div>
		  <p>Akce byla ukončena k 30. 04. 2021</p>
        {/* <p>
          Současný český mediální trh je ve velmi specifické situaci, kdy jsou
          větší mediální domy vlastněny jako účelové součásti velkých holdingů a
          ztrácí tak podstatnou část své nezávislosti a tím i svobody slova.
          Globální digitální společnosti převzaly podstatnou část příjmů
          tradičních médií, která jen těžko hledají náhradní zdroje financování.
          Současné spojení obou problémů způsobuje u malých vydavatelství
          balancování mezi nezávislostí a financemi, případně i zánikem.
        </p>
        <p>
          Přesto i u nás stále existují skutečně nezávislá média, která
          spoléhají na přízeň svých předplatitelů a sponzorů. Mají však trvalý
          nedostatek finančních prostředků, který ovlivňuje jejich kvalitu a
          brání jejich dalšímu možnému rozvoji. Nadace Svoboda slova a myšlení
          byla založena za účelem podpory nezávislosti médií. Po dlouhých
          diskusích jsme došli k myšlence propojit individuální akcionářství s
          předplatným.
        </p>
        <h2>
          Rozhodli jsme se naplnit název Nadace Svoboda slova a myšlení a
          realizovat výjimečný pilotní projekt ve financování médií. Rozdělíme
          mezi čtenáře a autory Echa majoritní balík akcií vydavatelství Echo
          Media a.s.
        </h2>
        <p>
          Nadace po dohodě s akcionáři získá 51 % plnohodnotných akcií Echo
          Media a.s. do svého majetku.
        </p>
        <p>
          Prvním cílem Nadace je: Darovat formou nadačního příspěvku každému
          předplatiteli, který o to požádá, ročního{" "}
          <a href="https://eshop.echomedia.cz/p/UAGhb/echo-komplet--voucher-na-akcii-echa">
            ECHO KOMPLET*
          </a>{" "}
          jeden kus akcie, a to až do vyčerpání k tomuto záměru určeného počtu
          akcií vlastněných Nadací.
        </p>
        <p>
          Druhým cílem Nadace je: Udělat akcionáře z redaktorů Echo Media a.s.
          tím, že mezi ně postupně rozdělí část akcií formou nadačního
          příspěvku.
        </p>
        <p>
          Třetím cílem Nadace je: Věnovat v následujících letech akcie dále těm,
          kteří se významně zaslouží
        </p>
        <p>
          o svobodu slova. Tím nadace ocení zásluhy na poli svobody slova a
          tisku.
        </p>
        <p>
          Pro předplatitele{" "}
          <a href="https://eshop.echomedia.cz/p/UAGhb/echo-komplet--voucher-na-akcii-echa">
            ECHO KOMPLET*
          </a>
          , kteří požádají též o nadační příspěvek, bude vydán vždy 1ks kusové
          akcie společnosti Echo Media a.s. vydané jako cenný papír (listinné).
        </p>
        <p>
          Vydavatelství Echo Media a Nadaci k tomuto rozhodnutí vede snaha co
          nejvíce otevřít práci redakce veřejnosti a vytvořit tak její užší
          vztah se čtenáři. Chceme, aby vznikl prostor pro bezproblémový přenos
          informací mezi předplatiteli–akcionáři a redakcí.
        </p>
        <p>
          Jde o zcela ojedinělý krok. Vydavatelství bude první, kdo se pokusí
          vytvořit nový model fungování médií. Echo Media a.s. bude prvním
          vydavatelem, jehož práci budou kontrolovat i ovlivňovat čtenáři, kteří
          budou zároveň i akcionáři.
        </p>
        <h2>
          Každá akcie bude symbolem příslušnosti k hodnotám ECHA, kterými jsou
          SVOBODA SLOVA, NEZÁVISLOST A SERIÓZNOST.{" "}
        </h2>
        <p>
          Současně chceme vytvářet prostor pro práci novinářů a autorů, kteří
          vyznávají identické hodnoty. Věříme, že tým autorů Echo bude mít v
          projektu velkou oporu a získá pevné zázemí.
        </p>
        <p>
          Víme, že role Nadace v projektu vzniku nového funkčního modelu
          fungování Echo Media a.s. skončí v okamžiku, kdy už nebude mít k
          rozdělení žádné akcie z počátečního 51% balíku.
        </p>
        <p>
          V tu chvíli bude jasné, že nový akcionářský model funguje, je
          životaschopný přinesl do práce vydavatelství potřebnou stabilitu.
        </p>
        <p>
          <strong>Správní rada Nadace Svobody slova a myšlení</strong>
        </p> */}
      </div>
      <div className={css.flaotingButtonWrapper}>
      <Link className={css.button} to='/postup-vyzvednuti-akcie'>Postup vyzvednutí akcie</Link>
      </div>
    </>
  );
};

export default IndexPage;
